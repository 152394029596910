import React from "react"
import Button from "components/Button"
import dbProxy from "Utility Groups/DbProxy"
import TextInput from "components/TextInput"
import ViewableWorkout from "components/ViewableWorkout"
import WorkoutDefinitionShorthand from "./CreateAndEdit/WorkoutDefinitionShorthand"
import Summary from "./Summary"
import WorkoutSelector from "./WorkoutSelector"
import Dialog from "components/Dialog"
import { intervalParser, intervalSerializer } from "utils/intervalParser"
import { Workout } from "types"
import { useMutation } from "@tanstack/react-query"

export default function CreateAndEdit() {
  const [id, setId] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)
  const [text, setText] = React.useState("")
  const [name, setName] = React.useState("")
  const [note, setNote] = React.useState("")
  const [show, setShow] = React.useState<"hint" | "summary">("summary")
  const mutation = useMutation({
    mutationFn: save,
  })

  const intervals = intervalParser(text)
  const workout: Workout = {
    id: id,
    name: name,
    intervals: intervals,
    notes: note,
  }
  const buttonText = workout.id !== 0 ? "Update" : "Save"

  const placeholder =
    "Psssst! Use the workout definition shorthand.\n\n" + "Example:\n" + "2 x (1mile@8:00) \n"

  function validateWorkout() {
    if (workout.intervals.length < 1) throw new Error("No intervals")
    if (!workout.name) throw new Error("No workout name")
  }

  function hydrateState(workout: Workout) {
    const text = intervalSerializer(workout.intervals)
    setText(text)
    setName(workout.name)
    setNote(workout?.notes || "")
    setId(workout.id)
  }

  async function save() {
    try {
      validateWorkout()
      if (workout.id !== 0) {
        // TODO: create a dbProxy.updateWorkout() function
        await dbProxy.deleteWorkout(workout.id)
      }
      const { id: newId } = (await dbProxy.saveWorkout(workout)) as Workout
      setId(newId)
      alert(`Workout ${buttonText}d`)
    } catch (e: any) {
      alert("Could not save workout")
    }
  }

  return (
    <>
      <div className="flex grow flex-col min-h-full gap-y-1">
        <div className="flex flex-col basis-1/2 gap-y-1 text-center">
          <Button text="Open Existing Workout" onClick={() => setIsOpen(true)} />
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Workout Name"
            style={{ paddingLeft: "1rem", border: "2px solid black" }}
          />
          <textarea
            value={text}
            placeholder={placeholder}
            onChange={(e) => setText(e.target.value)}
            style={{
              height: "125px",
              width: "100%",
              resize: "none",
              border: "2px solid black",
              borderRadius: "15px",
              padding: "0.5rem 1rem",
              boxShadow: "none",
              outline: "none",
            }}
          />
          <textarea
            value={note}
            placeholder={"Notes"}
            onChange={(e) => setNote(e.target.value)}
            style={{
              height: "45px",
              width: "100%",
              resize: "none",
              border: "2px solid black",
              borderRadius: "15px",
              padding: "0.5rem 1rem",
              boxShadow: "none",
              outline: "none",
            }}
          />
          <div className="flex gap-x-1 text-xs">
            <Button
              text="Hint"
              onClick={() => setShow("hint")}
              backgroundColor={show === "hint" ? "black" : "white"}
              color={show === "hint" ? "white" : "black"}
            />
            <Button
              text="Summary"
              onClick={() => setShow("summary")}
              backgroundColor={show === "summary" ? "black" : "white"}
              color={show === "summary" ? "white" : "black"}
            />
          </div>
          {show === "hint" && (
            <div className="flex flex-col gap-y-3">
              <WorkoutDefinitionShorthand />
              <Summary workouts={[workout]} isLoading={false} />
            </div>
          )}
        </div>
        <div className="flex grow flex-col basis-1/2">
          {show === "summary" && (
            <div className="border rounded-md border-solid bg-white p-4">
              <ViewableWorkout workout={workout} />
            </div>
          )}
        </div>
        <div className="flex">
          <Button
            text={`${mutation.isLoading ? "Saving" : buttonText} Workout`}
            onClick={mutation.mutate}
            className="bg-green text-white"
            color="white"
          />
        </div>
      </div>
      <Dialog
        isOpen={isOpen}
        acceptText={"Close"}
        accept={() => setIsOpen(false)}
        Body={<WorkoutSelector callback={hydrateState} />}
      />
    </>
  )
}
