import { inMemoryJwt } from "context/auth-context"

type FetchArgs = {
  url: string
  options: {
    method: "GET" | "POST" | "PUT" | "DELETE"
    headers?: HeadersInit
    credentials?: RequestCredentials
    body?: any
    bearer?: string
  }
  errorCallback?: () => any
}

const defaultOptions = {
  headers: { "Content-Type": "application/json" },
  credentials: "include" as RequestCredentials,
}

export const httpClient = async (args: FetchArgs) => {
  const options = { ...defaultOptions, ...args.options }
  options.body = options.body && JSON.stringify(args.options.body)
  options.headers = { ...options.headers, Authorization: "Bearer " + inMemoryJwt }

  const res = await fetch(args.url, options)
  return res
}
