import React from "react"
import Select from "react-select"
import { Workout, SavedWorkouts } from "types"
import dbProxy from "Utility Groups/DbProxy"

type Props = {
  callback: (workout: Workout) => any
}
export default function WorkoutSelector(props: Props) {
  const [workouts, setWorkouts] = React.useState<SavedWorkouts>()

  React.useEffect(() => {
    async function getWorkouts() {
      const workouts = await dbProxy.getSavedWorkouts()
      setWorkouts(workouts as SavedWorkouts)
    }
    getWorkouts()
  }, [])

  let workoutOptions: Array<{ value: Workout; label: string }> = []
  if (!!workouts) {
    workoutOptions = Object.values(workouts).map((workout) => ({
      value: workout,
      label: workout.name,
    }))
  }

  return (
    <div className="min-w-full">
      <Select
        placeholder="Select Workout"
        value={undefined}
        options={workoutOptions}
        onChange={(option: any) => props.callback(option.value)}
      />
    </div>
  )
}
