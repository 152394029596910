import Button from "components/Button"

type Props = {
  accept?: () => any
  decline?: () => any
  isOpen: boolean
  Body: string | JSX.Element
  acceptText?: string
  declineText?: string
}

export default function Dialog(props: Props) {
  const { accept, decline } = props
  const { Body, acceptText, declineText } = props

  const UI = (
    <div className="w-screen h-screen z-0 left-0 top-0 fixed grid place-content-center bg-translucent">
      <div className="min-h-[100px] max-h-[300px] w-[300px] bg-white rounded-lg">
        <div className="p-4">{Body}</div>
        <div className="p-2 flex justify-around gap-2">
          {acceptText && <Button text={acceptText} onClick={accept} />}
          {declineText && <Button text={declineText} onClick={decline} />}
        </div>
      </div>
    </div>
  )

  return props.isOpen ? UI : <div />
}
