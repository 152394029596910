import React from "react"
import AuthorizedApp from "AuthorizedApp"
import UnAuthorizedApp from "UnAuthorizedApp"
import LoadScreen from "components/LoadScreen"
import { useAuth } from "context/auth-context"
const initialHeight = window.innerHeight

export default function App() {
  console.log("App()")
  // This is to solve the innerHeight / 100vh webkit issue.
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight)
  const { isAuthenticated, isLoading } = useAuth()

  React.useEffect(() => {
    function setInnerHeight() {
      setScreenHeight(initialHeight)
    }

    window.addEventListener("deviceorientation", setInnerHeight)
    window.addEventListener("resize", setInnerHeight)

    return () => {
      window.removeEventListener("deviceorientation", setInnerHeight)
      window.removeEventListener("change", setInnerHeight)
    }
  }, [])

  return (
    <div className="flex md:place-content-center" style={{ height: screenHeight }}>
      <div
        className="
      flex grow flex-col md:min-h-[400px] md:m-2 md:max-w-screen-md 
      md:shadow-lg md:shadow-gray2 md:max-h-[1200px]"
      >
        {isLoading ? (
          <LoadScreen text="LOADING" />
        ) : isAuthenticated ? (
          <AuthorizedApp />
        ) : (
          <UnAuthorizedApp />
        )}
      </div>
    </div>
  )
}
