import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export default function Analytics() {
  return (
    <>
      <div className="text-xl font-bold text-center m-2">ANALYSIS TIME RANGE</div>
      <div className="flex content-around">
        <div className="flex basis-5/12 text-center">
          <DatePicker onChange={() => null} className="rounded-md border w-[150px]" />
        </div>
        <span className="flex justify-center basis-2/12 font-semibold">-</span>
        <div className="flex justify-center basis-5/12 text-center">
          <DatePicker onChange={() => null} className="rounded-md border w-[150px]" />
        </div>
      </div>
      <div className="text-center pt-10">This page coming soon.</div>
    </>
  )
}
