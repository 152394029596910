import { useLocation } from "react-router-dom"

export default function Header() {
  const location = useLocation()
  const path = location.pathname.split("/")[1]
  let title = path ? path[0].toUpperCase() + path.substring(1) : "Run Calendar"

  if (title === "Create") title = "Create & Edit"

  return (
    <div
      className="grid place-content-center border-x border-black md:text-4xl 
        font-bold basis-1/12 text-white text-2xl text-center bg-black"
    >
      {title}
    </div>
  )
}
