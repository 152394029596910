import React from "react"
import { format } from "date-fns"
import summariseWorkout from "utils/summariseWorkout"
import { numberAsTwoChars, toDistance, toHhMmSs } from "utils/converter"
import ViewableWorkout from "components/ViewableWorkout"
import { DropDownAnimation } from "components/DropDownAnimation"
import { ScheduledWorkout } from "types"

/* Day of Week: Name | Distance | Time */
type SummaryProps = {
  scheduledWorkout: ScheduledWorkout
}
export default function DaySummary(props: SummaryProps) {
  const { workout, date } = props.scheduledWorkout
  const isToday = date.toDateString() === new Date().toDateString()
  const [isOpen, setIsOpen] = React.useState(isToday)
  const totals = summariseWorkout({ workout })

  // Conditional Styling
  let openSummaryStyle = isOpen ? "rounded-t-md bg-gray6 text-white" : "rounded-md"
  let detailStyle = "border border-solid border-t-white"
  let todaySummaryStyle = isToday
    ? "bg-pink text-white border border-pink " + (isOpen ? "rounded-t-md" : "rounded-md")
    : ""
  let todayDetailStyle = isToday ? "border border-pink border-t-white" : ""

  // Derived Values
  const dayOfWeek = format(date, "eee").toLocaleUpperCase()
  const { distance, distanceUnit } = toDistance({
    measure: { distance: totals.distance ?? 0, distanceUnit: "meters" },
    toUnit: "miles",
  })
  const time = toHhMmSs(totals.time)

  const WorkoutDetail = (
    <div className={"p-2 rounded-b-md bg-white " + detailStyle + todayDetailStyle}>
      <ViewableWorkout workout={workout} />
    </div>
  )

  const style = isToday ? todaySummaryStyle : openSummaryStyle
  return (
    <div className="rounded-md shadow-md bg-white">
      <div
        className={"transition-rounded transition-bg duration-200 p-2 flex " + style}
        onClick={() => setIsOpen((state) => !state)}
      >
        <span className="basis-1/6 font-semibold">{dayOfWeek}</span>
        <span className="basis-2/6">{workout.name} </span>
        <span className="basis-2/6">
          {numberAsTwoChars(distance)}
          &nbsp;
          {distanceUnit}
        </span>
        <span className="basis-1/6">{time}</span>
      </div>
      <DropDownAnimation isOpen={isOpen}>{WorkoutDetail}</DropDownAnimation>
    </div>
  )
}
