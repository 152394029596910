import { EditableWorkout } from "types"
import { v4 } from "uuid"
import { PACE_PER_MILE } from "settings"

type Pace = {
  minutes: number | null
  seconds: number | null
}

type SimpleWorkout = {
  workout: EditableWorkout
  pace: Pace
}

const makeDefaultState = (idx: number): SimpleWorkout => {
  const id = v4().split("-")[0]
  return {
    workout: {
      id: idx,
      name: id,
      intervals: [
        {
          time: null,
          timeUnits: "minutes",
          distance: null,
          distanceUnits: "miles",
        },
      ],
    },
    pace: {
      minutes: PACE_PER_MILE.MINUTES,
      seconds: PACE_PER_MILE.SECONDS,
    },
  }
}

export default makeDefaultState
