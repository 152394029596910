import { useLocation, useNavigate } from "react-router-dom"
import { FaCalendarAlt as Calendar } from "react-icons/fa"
import { FaRunning as RunningMan } from "react-icons/fa"
import { FaRegListAlt as List } from "react-icons/fa"
import { FaCog as Cog } from "react-icons/fa"
import { IconType } from "react-icons/lib"

type Props = {
  Icon: IconType
  selected: boolean
  action: () => void
  onClick?: () => void
}
const Button = ({ Icon, action, selected = false }: Props) => {
  const fillColor = selected ? "fill-pink" : "fill-gray1"
  return (
    <span onClick={action} style={{ cursor: "pointer" }}>
      <Icon className={`h-6 md:h-8 w-6 md:w-8 ${fillColor} active:fill-light-pink`} />
    </span>
  )
}

export default function Footer() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  return (
    <div className="flex grow shadow-md border-b border-x border-gray1 basis-2/11">
      <div className="grid basis-1/4 place-content-center">
        <Button
          Icon={RunningMan}
          action={() => navigate({ pathname: "/", search: search })}
          selected={pathname === "/"}
        />
      </div>
      <div className="grid basis-1/4 place-content-center">
        <Button
          Icon={Calendar}
          action={() => navigate({ pathname: "/schedule", search: search })}
          selected={pathname === "/schedule"}
        />
      </div>
      {/* <div className="grid basis-1/4 place-content-center">
        <Button
          Icon={Chart}
          action={() => navigate("/analytics")}
          selected={pathname === "/analytics"}
        />
      </div> */}
      <div className="grid basis-1/4 place-content-center">
        <Button
          Icon={List}
          action={() => navigate({ pathname: "/create", search: search })}
          selected={pathname === "/create"}
        />
      </div>

      <div className="grid basis-1/4 place-content-center">
        <Button
          Icon={Cog}
          action={() => navigate({ pathname: "/settings", search: search })}
          selected={pathname === "/settings"}
        />
      </div>
    </div>
  )
}
