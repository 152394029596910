import React from "react"

type Props = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  placeholder?: string
  type?: string
  required?: boolean
  style?: any
}
export default function TextInput(props: Props) {
  const { type = "text", required = false } = props

  return (
    <div className="flex grow flex-col">
      <div>
        <label className="font-bold">{props.label}</label>
      </div>
      <input
        placeholder={props.placeholder}
        type={type}
        required={required}
        value={props.value}
        onChange={props.onChange}
        className="p-1 rounded-lg border"
        style={{ ...props?.style }}
      />
    </div>
  )
}
