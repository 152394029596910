import { Workout, Interval } from "types"
import { toDistance, toTime } from "./converter"

type Props = {
  workout: Workout
}

function summariseWorkout({ workout }: Props) {
  const totals = workout.intervals.reduce<Partial<Interval>>(
    (pv, cv) => {
      const distance = toDistance({
        measure: {
          distance: cv.distance,
          distanceUnit: cv.distanceUnits ?? "miles",
        },
        toUnit: "meters",
      }).distance

      const time = toTime({
        measure: { time: cv.time, timeUnit: cv.timeUnits },
        toUnit: "seconds",
      }).time

      return {
        time: time + (pv.time ?? 0),
        distance: distance + (pv.distance ?? 0),
      }
    },
    { time: 0, distance: 0 }
  )
  return totals
}

export default summariseWorkout
