import Calendar, { OnChangeDateRangeCallback } from "react-calendar"
import "react-calendar/dist/Calendar.css"
import "components/Calendar/calendar.css"

type Props = {
  selectedDate: Date
  selectionHandler: OnChangeDateRangeCallback
}
export default function WorkoutCalendar(props: Props) {
  return <Calendar value={props.selectedDate} onChange={props.selectionHandler} />
}
