import Button from "components/Button"
import { useAuth0 } from "@auth0/auth0-react"
import Logo from "./logo/logo3x.png"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Link } from "react-router-dom"

export default function LoginScreen() {
  const { loginWithRedirect } = useAuth0()

  return (
    <div className="flex flex-col flex-grow justify-center items-center bg-gray0 min-h-full">
      <form className="flex flex-col gap-y-6" style={{ minWidth: 250 }}>
        <div className="flex justify-center items-center">
          <span className="text-[2.5rem] font-bold">RunCalendar</span>
          <img className="self-center" width={50} src={Logo} />
        </div>
        <div className="flex justify-center rounded-md bg-gray4">
          <Carousel
            showThumbs={false}
            width={200}
            showStatus={false}
            autoPlay
            infiniteLoop
            className="p-2"
          >
            <div>
              <img src="home.jpg" className="rounded-md" />
            </div>
            <div>
              <img src="schedule.jpg" className="rounded-md" />
            </div>
            <div>
              <img src="create.jpg" className="rounded-md" />
            </div>
          </Carousel>
        </div>
        <div className="p-2">
          <strong>RunCalendar</strong> is a <span className="underline">free</span> and{" "}
          <span className="italic">easy</span> way to plan and track your running schedule. Sign up
          below.
        </div>
        <div className="self-center">
          <Button onClick={loginWithRedirect} text="Access RunCalendar" />
        </div>
        <div className="underline">
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </form>
    </div>
  )
}
