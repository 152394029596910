import { Calendar, ScheduledWorkout } from "types"

function calendarToWorkouts(calendar: Calendar): Array<ScheduledWorkout> {
  let scheduledWorkouts: Array<ScheduledWorkout> = []
  Object.entries(calendar).forEach(([dateStr, workouts]) => {
    workouts.forEach((workout) => {
      scheduledWorkouts = [
        ...scheduledWorkouts,
        {
          workout: workout,
          date: new Date(dateStr),
        },
      ]
    })
  })
  return scheduledWorkouts
}

export { calendarToWorkouts }
