import { Interval } from "types"
import { numberAsTwoChars, paceToSeconds } from "./converter"
import { toPace } from "./converter"

const intervalRegex =
  /((?<repetitions>[0-9]+)(\s|)x(\s|))?\(?(?<distance>[0-9]+)(?<distanceUnits>[aA-zZ]+)@(?<pace>[0-9]+:[0-5][0-9])\)?/

const pluralizeInput = (word: string): string => {
  const lastLetter = word.slice(-1)
  return lastLetter === "s" ? word : word + "s"
}

const validateWithOptions = (input: string, options: Array<string>): boolean => {
  return options.includes(input)
}

const makeInterval = (text: string): Array<Interval> => {
  try {
    const matches = text.toLowerCase().match(intervalRegex)
    const groups = matches?.groups

    const repetitions = Number(groups?.repetitions || 1)

    // Performing pace conversion
    if (!groups?.pace) throw Error("Pace not present")
    const time = paceToSeconds(groups.pace)

    // Performing distance checks
    if (!groups?.distance) throw Error("Distance not present")
    const distance = Number(groups.distance)

    // Performing Distance Units conversion / checks
    if (!groups?.distanceUnits) throw Error("Distance Units not present")
    const distanceUnits = pluralizeInput(groups.distanceUnits)
    validateWithOptions(distanceUnits, ["miles", "kilometers", "meters"])

    let intervalArray: Array<Interval> = []
    for (let i = 0; i < repetitions; i++) {
      intervalArray.push({
        distance: distance,
        distanceUnits: distanceUnits as Interval["distanceUnits"],
        time: time * distance,
        timeUnits: "seconds",
      })
    }
    return intervalArray
  } catch (e: any) {
    return []
  }
}

export const intervalParser = (text: string): Array<Interval> => {
  const lines = text.split("\n")
  const intervals = lines.reduce(
    (prev: Array<Interval>, curr: string) => [...prev, ...makeInterval(curr)],
    []
  )
  return intervals
}

export const intervalSerializer = (intervals: Array<Interval>): string => {
  let text = ""
  intervals.forEach((interval) => {
    const { minutes, seconds } = toPace(interval)
    const minFmt = numberAsTwoChars(minutes)
    const secFmt = numberAsTwoChars(seconds)
    text += `${interval.distance}${interval.distanceUnits}@${minFmt}:${secFmt}\n`
  })
  return text
}
