import React from "react"
import Dialog from "components/Dialog"
import FloatingPaper from "./FloatingPaper"

type Props = {
  removeHandler: () => void
  children: any
}

export default function RemovableWindow(props: Props) {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  return (
    <FloatingPaper className="mt-1">
      <span
        onClick={() => setDialogOpen(true)}
        className="float-right font-semibold text-xs cursor-pointer"
      >
        REMOVE
      </span>
      {props.children}
      <Dialog
        Body="Are you sure you want to delete that?"
        acceptText="Yes"
        declineText="No"
        isOpen={dialogOpen}
        accept={() => props.removeHandler()}
        decline={() => setDialogOpen(false)}
      />
    </FloatingPaper>
  )
}
