import DaySummary from "components/DaySummary"
import { ScheduledWorkout } from "types"

/* List of Day Summaries */
interface Props {
  schedule: Array<ScheduledWorkout>
}
export default function Planner(props: Props) {
  const UI = props.schedule.map((item, idx) => (
    <DaySummary key={item.workout.name + idx} scheduledWorkout={item} />
  ))
  return (
    <>
      <div className="flex flex-col gap-y-2">{UI}</div>
    </>
  )
}
