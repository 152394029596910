export default function WorkoutDefinitionShorthand() {
  return (
    <div>
      <div className="text-lg font-bold">Workout Definition Shorthand</div>
      <div className="text-md">
        <div>[repetitions] x (interval)</div>
        <div className="italic">where</div>
        <div>interval = (distance-unit@pace)</div>
        <div className="italic">e.g.</div>
        <div>2 x (2miles@8:00)</div>
      </div>
    </div>
  )
}
