import React from "react"
import defaultState from "./SimpleWorkout/simpleWorkoutDefaultState"
import { Interval } from "types"
import { numberAsTwoChars, stringToNumberInput } from "utils/converter"
import FloatingPaper from "components/FloatingPaper"
import Button from "components/Button"
import { PACE_PER_MILE } from "settings"

type Props = {
  save: any
  idx: number
}

export default function QuickWorkout(props: Props) {
  const [state, dispatch] = React.useReducer(
    (state: any, action: { type: string; payload?: any }): any => {
      type PaceUpdate = { minutes: number; seconds: number }

      function makeUpdate(interval: Interval, pace: PaceUpdate) {
        const totalTime = (pace.minutes * 60 + pace.seconds) * interval.distance
        const paceUpdate = { time: totalTime, timeUnits: "seconds" }
        const updatedInterval = {
          ...state.workout.intervals[0],
          ...interval,
          ...paceUpdate,
        }
        const intensity = PACE_PER_MILE.MINUTES < pace.minutes ? "Hard" : "Easy"
        const name = `${intensity} ${interval.distance}  ${interval.distanceUnits}`
        const updatedState = {
          workout: {
            ...state.workout,
            name: name,
            intervals: [updatedInterval],
          },
          pace: pace,
        }
        return updatedState
      }

      let update
      switch (action.type) {
        case "interval":
          update = makeUpdate({ ...state.workout.intervals[0], ...action.payload }, state.pace)
          return update
        case "pace":
          update = makeUpdate(state.workout.intervals[0], action.payload)
          return update
        case "reset":
          return defaultState(props.idx)
        default:
          return defaultState(props.idx)
      }
    },
    defaultState(props.idx)
  )

  function submit() {
    props.save(state.workout)
    dispatch({ type: "reset" })
  }

  const { distance, distanceUnits } = state.workout.intervals[0]

  return (
    <FloatingPaper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-around">
          <div className="basis-1/6 text-center">
            <label>Distance</label>
          </div>
          <div className="basis-2/6">
            <input
              type="number"
              value={distance ?? ""}
              onChange={(e) => {
                dispatch({
                  type: "interval",
                  payload: { distance: Number(e.target.value) },
                })
              }}
              className="bg-gray1 rounded-md text-center w-[100%]"
            />
          </div>
          <div className="basis-2/6 text-center">
            <select
              value={distanceUnits}
              onChange={(e) => {
                dispatch({
                  type: "interval",
                  payload: { distanceUnits: e.target.value },
                })
              }}
              className="w-[100%]"
            >
              <option value="miles">miles</option>
              <option value="kilometers">kilometers</option>
            </select>
          </div>
        </div>

        <div className="flex justify-around">
          <div className="basis-1/6">Pace</div>
          <div className="flex basis-2/6 justify-center">
            <div>
              <input
                type="text"
                value={numberAsTwoChars(state.pace.minutes)}
                placeholder="mm"
                onChange={(e) =>
                  dispatch({
                    type: "pace",
                    payload: {
                      ...state.pace,
                      minutes: stringToNumberInput(e),
                    },
                  })
                }
                className="bg-gray1 text-center rounded-md w-[100%]"
              />
            </div>
            <div>:</div>
            <div>
              <input
                type="text"
                value={numberAsTwoChars(state.pace.seconds)}
                placeholder="ss"
                onChange={(e) =>
                  dispatch({
                    type: "pace",
                    payload: {
                      ...state.pace,
                      seconds: stringToNumberInput(e),
                    },
                  })
                }
                className="bg-gray1 text-center rounded-md w-[100%]"
              />
            </div>
          </div>
          <label className="basis-2/6"> mins / {distanceUnits.slice(0, -1)}</label>
        </div>
        <div className="flex items-center">
          <Button text="Save Workout" onClick={submit} className="bg-green text-white" />
        </div>
      </div>
    </FloatingPaper>
  )
}
