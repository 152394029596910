import LoginScreen from "LoginScreen"
import PrivacyPolicy from "PrivacyPolicy"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

export default function UnAuthorizedApp() {
  return (
    <>
      <BrowserRouter>
        <div
          className="grow p-2 basis-9/12 bg-gray0 border-x border-x-gray1 
          shadow-inner-md shadow-inner overflow-y-auto"
        >
          <Routes>
            {/* <Route path="/signup" element={<SignUpScreen />} /> */}
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            {/* <Route path="/reset" element={<ResetScreen />} /> */}
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  )
}
