import React from "react"
import "components/DropdownAnimation/dropdown.css"

type Props = {
  isOpen: boolean
  children: any
}

export function DropDownAnimation(props: Props) {
  const ref = React.useRef(null)
  const [elementHeight, setElementHeight] = React.useState(0)

  React.useEffect(() => {
    const { height } = (
      ref.current as unknown as HTMLElement
    ).getBoundingClientRect()
    setElementHeight(height + 0.5)
  }, [ref])

  const height = props.isOpen ? elementHeight : 0
  return (
    <div className={"dropdown"} style={{ height: height }}>
      <div ref={ref}>{props.children}</div>
    </div>
  )
}
