import Home from "App/Home"
import Schedule from "App/Schedule"
import Settings from "App/Settings"
import Analytics from "App/Analytics"
import Header from "App/Header"
import Footer from "App/Footer"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import CreateAndEdit from "App/CreateAndEdit"
import PrivacyPolicy from "PrivacyPolicy"

export default function AuthorizedApp() {
  console.log("AuthorizedApp()")
  return (
    <>
      <BrowserRouter>
        <Header />
        <div
          className="grow p-2 basis-9/12 bg-gray0 border-x border-x-gray1 
          shadow-inner-md shadow-inner overflow-y-auto"
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="create" element={<CreateAndEdit />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="settings" element={<Settings />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  )
}
