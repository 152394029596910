import React from "react"
import { createRoot } from "react-dom/client"
import App from "App"
import "index.css"
import { AuthProvider } from "context/auth-context"
import { Auth0Provider } from "@auth0/auth0-react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "react-loading-skeleton/dist/skeleton.css"

// Locking Screen Orientation. This was previously failing on mobile chrome
try {
  if (!!window.screen.orientation.lock) {
    window.screen.orientation.lock("portrait").catch((e) => console.log(e))
  }
} catch (e) {
  console.log(e)
}

const queryClient = new QueryClient()

// Setting up React App
const container = document.getElementById("root") as Element
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain="dev-l5v78jdzeysx86cl.us.auth0.com"
        clientId="besqKMytg5YceMl3cdrtJgqNy03tMvqr"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "/api",
        }}
      >
        <AuthProvider>
          <App />
        </AuthProvider>
      </Auth0Provider>
    </QueryClientProvider>
  </React.StrictMode>
)
