import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import dbProxy from "Utility Groups/DbProxy"

export type SignUpArgs = {
  email: string
  password: string
  alias: string
}

type Props = {
  jwt: string | undefined
  setJwt: (x: string) => void
  isAuthenticated: boolean
  isLoading: boolean
}

const AuthContext = React.createContext<Props>({
  jwt: undefined,
  setJwt: () => null,
  isAuthenticated: false,
  isLoading: false,
})

export let inMemoryJwt = ""

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const {
    user,
    isAuthenticated: auth0Authenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    isLoading,
  } = useAuth0()

  const [state, setState] = React.useState({
    jwt: "",
    authenticated: false,
  })

  useEffect(() => {
    async function getJwtAndLogin() {
      try {
        if (auth0Authenticated) {
          inMemoryJwt = await getAccessTokenSilently()
          setState({ jwt: inMemoryJwt, authenticated: true })

          if (user?.email && user?.name) {
            dbProxy.signUp({
              email: user.email,
              alias: user.name,
              password: "00000000000000",
            })
          }
        }
      } catch (e: any) {
        console.log(e)
        if (e?.error) {
          if (e.error === "login_required") loginWithRedirect()
          if (e.error === "consent_required") loginWithRedirect()
        }
      }
    }
    getJwtAndLogin()
  }, [auth0Authenticated])

  const setJwt = (jwt: string) => setState((prevState) => ({ ...prevState, jwt: jwt }))

  return (
    <AuthContext.Provider
      value={{
        jwt: state.jwt,
        setJwt,
        isAuthenticated: auth0Authenticated && !!state.jwt,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)
