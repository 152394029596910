import Planner from "./Planner"
import Summary from "./Summary"
import PlannerPicker from "./Home/PlannerPicker"
import { ScheduledWorkout } from "types"
import dbProxy from "Utility Groups/DbProxy"
import { startOfWeek, endOfWeek, addDays, subDays } from "date-fns"
import { calendarToWorkouts } from "utils/typeConverters"
import { useQuery } from "@tanstack/react-query"
import { useSearchParams } from "react-router-dom"
import LoadScreen from "components/LoadScreen"

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams()

  const fromDate = searchParams.get("from")
    ? new Date(searchParams.get("from") as string)
    : startOfWeek(new Date(), { weekStartsOn: 1 })

  const toDate = searchParams.get("to")
    ? new Date(searchParams.get("to") as string)
    : endOfWeek(new Date(), { weekStartsOn: 1 })

  const query = useQuery({
    queryKey: ["scheduledWorkouts", fromDate.toDateString(), toDate.toDateString()],
    queryFn: fetchData,
  })

  async function fetchData() {
    const calendar = await dbProxy.getCalendarSlice(fromDate.toDateString(), toDate.toDateString())
    if (calendar) {
      const workouts = calendarToWorkouts(calendar)
      return workouts as ScheduledWorkout[]
    }
    return []
  }

  function setFromDate(date: Date) {
    setSearchParams({ from: date.toDateString(), to: addDays(date, 6).toDateString() })
  }

  function setToDate(date: Date) {
    setSearchParams({ from: subDays(date, 6).toDateString(), to: date.toDateString() })
  }

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <PlannerPicker
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        {query.data?.length === 0 && (
          <div className="text-center text-lg font-semibold italic">No Workouts In Range</div>
        )}
        {query.isLoading ?? <LoadScreen text="---Loading Workouts---" />}
        <Planner schedule={query.data || []} />
        <Summary
          workouts={query.data?.map((entry) => entry.workout) || []}
          isLoading={query.isLoading}
        />
      </div>
    </>
  )
}
