import Button from "components/Button"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "react-router-dom"

export default function Settings() {
  const { logout } = useAuth0()
  const origin = new URL(window.location.href).origin
  return (
    <div className="flex flex-col items-center gap-y-2">
      <Link to="/privacy" className="flex w-[75%]">
        <Button text="Privacy Policy" onClick={null} />
      </Link>
      <div className="flex w-[75%]">
        <Button text="Logout" onClick={() => logout({ logoutParams: { returnTo: origin } })} />
      </div>
    </div>
  )
}
