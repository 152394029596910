import React from "react"
import summariseWorkout from "utils/summariseWorkout"
import { Workout, Interval } from "types"
import { numberAsTwoChars, toDistance, toHhMmSs, toPace } from "utils/converter"

type Props = {
  workout: Workout
  hideIntervals?: boolean
}
export default function ViewableWorkout({ workout, hideIntervals }: Props) {
  const [selectedUnits, setSelectedUnits] = React.useState<{
    distance: Interval["distanceUnits"]
    time: Interval["timeUnits"]
  }>({
    distance: "miles",
    time: "minutes",
  })

  const totals = summariseWorkout({ workout })

  const convertedDistance = toDistance({
    measure: { distance: totals.distance ?? 0, distanceUnit: "meters" },
    toUnit: selectedUnits.distance,
  }).distance

  const IntervalsUI = hideIntervals ? (
    <div />
  ) : (
    workout.intervals.map((interval, idx) => {
      const { minutes, seconds } = toPace(interval)
      const mins = numberAsTwoChars(minutes)
      const secs = numberAsTwoChars(seconds)
      return (
        <div key={idx}>
          {interval.distance} {interval.distanceUnits} @ {mins}:{secs} pace
        </div>
      )
    })
  )

  return (
    <>
      <div className="text-lg font-bold">{workout.name || "Workout Title"}</div>
      {IntervalsUI}
      <div>
        <label>Total Distance: {convertedDistance.toFixed(2)}</label>
        <select
          value={selectedUnits.distance}
          onChange={(e) => {
            setSelectedUnits((state) => ({
              ...state,
              distance: e.target.value as Interval["distanceUnits"],
            }))
          }}
          className="rounded-md bg-gray0 font-semibold ml-1 border"
        >
          <option value="miles" label="miles" />
          <option value="kilometers" label="kilometers" />
        </select>
      </div>

      <div>
        <label>Total Time: {toHhMmSs(totals.time ?? 0)} </label>
      </div>
    </>
  )
}
