import React from "react"
import { Interval, Pace } from "types"

/***********************************/
/* Conversion utility for distance */
/***********************************/
type DistanceMeasure = {
  distance: number
  distanceUnit: Interval["distanceUnits"] | "meters"
}

type DistanceProps = {
  measure: DistanceMeasure
  toUnit: Interval["distanceUnits"] | "meters"
}

const toDistance = ({ measure, toUnit }: DistanceProps) => {
  let distanceInMeters
  switch (measure.distanceUnit) {
    case "miles":
      distanceInMeters = measure.distance * 1609.34
      break
    case "kilometers":
      distanceInMeters = measure.distance * 1000
      break
    case "meters":
      distanceInMeters = measure.distance
      break
  }

  let distanceInUnit
  switch (toUnit) {
    case "miles":
      distanceInUnit = distanceInMeters / 1609.34
      break
    case "kilometers":
      distanceInUnit = distanceInMeters / 1000
      break
    case "meters":
      distanceInUnit = distanceInMeters
      break
  }

  const roundedDistance = Math.round(distanceInUnit * 100) / 100
  return { distance: roundedDistance, distanceUnit: toUnit }
}

/*******************************/
/* Conversion utility for time */
/*******************************/
type TimeMeasure = {
  time: number
  timeUnit: Interval["timeUnits"]
}

type TimeProps = {
  measure: TimeMeasure
  toUnit: Interval["timeUnits"]
}

const toTime = ({ measure, toUnit }: TimeProps) => {
  let timeInSeconds: number
  switch (measure.timeUnit) {
    case "minutes":
      timeInSeconds = measure.time * 60
      break
    case "hours":
      timeInSeconds = measure.time * 3600
      break
    case "seconds":
      timeInSeconds = measure.time
      break
  }

  let timeInUnit: number
  switch (toUnit) {
    case "minutes":
      timeInUnit = timeInSeconds / 60
      break
    case "hours":
      timeInUnit = timeInSeconds / 3600
      break
    case "seconds":
      timeInUnit = timeInSeconds
      break
  }

  return { time: timeInUnit, timeUnit: toUnit }
}

const toHhMmSs = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return ""
  }
  const hours = String(Math.floor(seconds / 3600))
  const minutes = String(Math.floor((seconds % 3600) / 60))
  const remSeconds = String(Math.floor(seconds % 60))

  function twoChars(str: string) {
    if (str.length < 2) return "0" + str
    return str
  }

  const hh = twoChars(hours)
  const mm = twoChars(minutes)
  const ss = twoChars(remSeconds)

  return `${hh}:${mm}:${ss}`
}

const stringToNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const numberInput = Number(e.target.value)
  if (isNaN(numberInput)) return
  return numberInput
}

const numberAsTwoChars = (number?: number | null) => {
  if (!number) return "00"
  if (number < 10) {
    return "0" + String(number)
  }
  return String(number)
}

/*******************************/
/* Conversion utility for pace */
/*******************************/
const toPace = (interval: Interval): Pace => {
  const { time: ttlSeconds } = toTime({
    measure: { time: interval.time, timeUnit: interval.timeUnits },
    toUnit: "seconds",
  })

  const avgSeconds = Math.ceil(ttlSeconds / interval.distance)
  const pace = toHhMmSs(avgSeconds)
  const [hours, minutes, seconds] = pace.split(":")
  return {
    hours: Number(hours ?? 0),
    minutes: Number(minutes),
    seconds: Number(seconds),
    perUnit: interval.distanceUnits,
  }
}

const paceToSeconds = (text: string): number => {
  if (!text.match(/[0-9]+:[0-5][0-9]/)) {
    throw Error("pace is not in correct format (e.g. 8:00)")
  }

  const [minutes, seconds] = text.split(":")
  const totalSeconds = Number(minutes) * 60 + Number(seconds)
  return totalSeconds
}

export {
  toDistance,
  toTime,
  toHhMmSs,
  stringToNumberInput,
  numberAsTwoChars,
  toPace,
  paceToSeconds,
}
