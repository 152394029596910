import { Workout, Interval } from "types"

// Validates interval. If any one property is not valid or is missing,
// then an error message is returned. Returning a single error message is purposeful
// as to not overwhelm the user.
type ValidateInterval = (interval: Interval) => [boolean, string | undefined]
const validateInterval: ValidateInterval = (interval) => {
  let message = undefined

  if (!!interval.time) message = "Time Missing"
  if (!!interval.timeUnits) message = "Time Units Missing"
  if (!!interval.distance) message = "Distance Missing"
  if (!!interval.distanceUnits) message = "Distance Units Missing"

  return [typeof message === "undefined", message]
}

// Validates workout. If any one property is not valid or is missing,
// then an error message is returned. Returning a single error message is purposeful
// as to not overwhelm the user.
type ValidateWorkout = (workout: Workout) => [boolean, Array<string | undefined>]
const validateWorkout: ValidateWorkout = (workout) => {
  let message = undefined

  const invalidIntervalMessages = workout.intervals.map((interval) => {
    const [, msgs] = validateInterval(interval)
    return msgs
  })

  if (!!workout.id) message = "ID Missing"
  if (!!workout.name) message = "Workout Name Missing"

  const messages = [...invalidIntervalMessages, message].filter(
    (msg) => typeof msg !== "undefined"
  )
  return [messages.length > 0, messages]
}

export default validateWorkout
export { validateInterval }
