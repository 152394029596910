type Props = {
  text: string
  onClick: any
  backgroundColor?: string
  color?: string
  className?: string
  type?: "warning" | "default"
}

export default function Button(props: Props) {
  let style = ""
  switch (props.type) {
    case "warning":
      style = "bg-pink text-white"
      break
    default:
      style = "bg-white hover:bg-black hover:text-white active:bg-gray6"
  }

  return (
    <button
      className={
        style +
        " " +
        "flex grow justify-center " +
        "border-solid text-black hover:bg-black active:bg-gray6 " +
        "border rounded-lg p-2 hover:text-white font-semibold " +
        props.className
      }
      style={{
        backgroundColor: props?.backgroundColor,
        color: props?.color,
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  )
}
