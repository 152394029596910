import DatePicker from "react-datepicker"

type Props = {
  fromDate: Date
  toDate: Date
  setFromDate: (date: Date) => void
  setToDate: (date: Date) => void
}

export default function RangePicker(props: Props) {
  console.log("RangePicker()")
  return (
    <div className="flex justify-around">
      <div>
        <DatePicker
          className="max-w-[150px] border rounded text-center"
          value={props.fromDate?.toDateString()}
          onChange={props.setFromDate}
          calendarStartDay={1}
        />
      </div>
      <div>-</div>
      <div>
        <DatePicker
          className="max-w-[150px] border rounded text-center"
          value={props.toDate?.toDateString()}
          onChange={props.setToDate}
          calendarStartDay={1}
        />
      </div>
    </div>
  )
}
