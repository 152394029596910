import { Calendar, SavedWorkouts } from "types"
import { Workout } from "types"
import { httpClient } from "httpClient"
import { SignUpArgs } from "context/auth-context"

const { REACT_APP_URL_PREFIX = "http://localhost:8080" } = process.env

const dbProxy = {
  async login(args: { email: string; password: string }) {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/login",
        options: {
          method: "POST",
          body: args,
        },
      })
      return res
    } catch (e) {
      console.log("Could not log in")
    }
  },

  async logout() {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/logout",
        options: { method: "POST" },
      })
      return res
    } catch (e) {
      console.log("Could not log in")
    }
  },

  async signUp(args: SignUpArgs): Promise<Response | void> {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/user",
        options: {
          method: "POST",
          body: args,
        },
      })
      return res
    } catch (e) {
      console.log("Could not sign up")
    }
  },

  async saveWorkout(workout: Workout) {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/workouts",
        options: {
          method: "POST",
          body: workout,
        },
      })
      const { workout: createdWorkout } = await res.json()
      return createdWorkout as Workout
    } catch (e) {
      console.log("Could not save workout")
    }
  },

  async deleteWorkout(workoutId: number) {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + `/workouts/${workoutId}`,
        options: {
          method: "DELETE",
        },
      })
      const { workouts } = await res.json()
      return workouts as SavedWorkouts
    } catch (e) {
      console.log("Could not delete workout")
    }
  },

  async getSavedWorkouts() {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/workouts",
        options: { method: "GET" },
      })
      const { workouts } = await res.json()
      return workouts as SavedWorkouts
    } catch {
      console.log("Could not save workouts")
    }
  },

  async saveCalendar(calendar: Calendar) {
    try {
      await httpClient({
        url: REACT_APP_URL_PREFIX + "/calendar",
        options: { method: "POST", body: calendar },
      })
    } catch (e) {
      console.log("Could not save calendar", e)
    }
  },

  async getCalendar(): Promise<Calendar | undefined> {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + "/calendar",
        options: { method: "GET" },
      })
      const { calendar } = await res.json()
      return calendar
    } catch (e) {
      console.log("Could not retrieve calendar")
    }
  },

  async getCalendarSlice(from: string, to: string): Promise<Calendar | undefined> {
    try {
      const res = await httpClient({
        url: REACT_APP_URL_PREFIX + `/calendar/from/${from}/to/${to}`,
        options: { method: "GET" },
      })
      const { calendar } = await res.json()
      return calendar
    } catch (e) {
      console.log("Could not retrieve calendar")
    }
  },
}

export default dbProxy
