import React from "react"
import CalendarComponent from "../components/Calendar"
import { OnChangeDateRangeCallback } from "react-calendar"
import ScheduledWorkouts from "./SimpleWorkout/Schedule/ScheduledWorkouts"
import { Workout } from "types"
import QuickWorkout from "./QuickWorkout"
import WorkoutSelector from "./WorkoutSelector"
import Button from "components/Button"
import validateWorkout from "utils/validateWorkout"
import dbProxy from "Utility Groups/DbProxy"
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query"

export default function Schedule() {
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [selectorIsDropdown, setSelectorIsDropdown] = React.useState(true)

  const queryClient = useQueryClient()
  const calendarQuery = useQuery({ queryKey: ["calendar"], queryFn: dbProxy.getCalendar })
  const calendarMutation = useMutation({
    mutationFn: dbProxy.saveCalendar,
    onMutate: (data) => {
      queryClient.setQueryData(["calendar"], () => data)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["calendar"] })
    },
  })

  const selectedWorkouts = calendarQuery.data?.[selectedDate.toDateString()] || []

  async function save(workout: Workout) {
    const [isValid, message] = validateWorkout(workout)

    if (isValid) {
      const _calendar = { ...calendarQuery.data }
      _calendar[selectedDate.toDateString()] = [...selectedWorkouts, workout]
      calendarMutation.mutate(_calendar)
    } else {
      alert(message)
    }
  }

  type HandlerType = OnChangeDateRangeCallback
  const selectionHandler: HandlerType = (values: [Date] | [Date, Date]) => {
    const firstValue = Array.isArray(values) ? values[0] : values
    setSelectedDate(firstValue)
  }

  async function deleteWorkout(date: Date, id: number) {
    const _calendar = { ...calendarQuery.data }
    const values = _calendar[date.toDateString()].filter((workout) => workout.id !== id)

    if (values.length > 0) _calendar[date.toDateString()] = values
    else _calendar[date.toDateString()] = []
    calendarMutation.mutate(_calendar)
  }

  const ExistingWorkouts = () => {
    try {
      return (
        <ScheduledWorkouts
          workouts={selectedWorkouts}
          onDelete={(id: number) => deleteWorkout(selectedDate, id)}
        />
      )
    } catch {
      return <div />
    }
  }

  const Selector = selectorIsDropdown ? (
    <WorkoutSelector callback={save} />
  ) : (
    <QuickWorkout save={save} idx={selectedWorkouts.length} />
  )

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="grid place-content-center md:basis-6/12">
          <CalendarComponent selectedDate={selectedDate} selectionHandler={selectionHandler} />
        </div>
        <div className="flex flex-col md:basis-6/12">
          <div className="flex mt-2 mb-2">
            <div className="flex grow basis-3/6 mr-1">
              <Button
                text="Saved Workouts"
                onClick={() => setSelectorIsDropdown(true)}
                className="text-black"
              />
            </div>
            <div className="flex grow basis-3/6 ml-1">
              <Button
                text="Quick Workout"
                onClick={() => setSelectorIsDropdown(false)}
                className="text-black"
              />
            </div>
          </div>
          <div className="pb-2">{Selector}</div>
        </div>
      </div>
      <ExistingWorkouts />
    </>
  )
}
