import { Interval, Workout } from "types"
import { toDistance, toHhMmSs } from "utils/converter"
import summariseWorkout from "utils/summariseWorkout"
import Skeleton from "react-loading-skeleton"

type Props = {
  workouts: Array<Workout>
  distanceUnit?: Interval["distanceUnits"]
  isLoading: boolean
}
export default function Summary(props: Props) {
  const { distanceUnit = "miles" } = props
  const summaries = props.workouts.map((workout) => summariseWorkout({ workout }))

  const totals = summaries.reduce(
    (pv, cv) => {
      return {
        distance: (pv.distance ?? 0) + (cv.distance ?? 0),
        time: (pv.time ?? 0) + (cv.time ?? 0),
      }
    },
    { time: 0, distance: 0 }
  )

  const convertedDistance = toDistance({
    measure: { distance: totals.distance ?? 0, distanceUnit: "meters" },
    toUnit: distanceUnit,
  }).distance

  return props.isLoading ? (
    <Skeleton height={40} count={7} />
  ) : (
    <div className="flex justify-center text-xl gap-x-2 border-2 rounded-md border-green bg-white shadow-md font-bold">
      <span>
        {" "}
        {convertedDistance} {distanceUnit}
      </span>
      <span> - </span>
      <span>{toHhMmSs(totals.time)}</span>
    </div>
  )
}
