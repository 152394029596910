import ViewableWorkout from "components/ViewableWorkout"
import RemovableWindow from "components/RemovableWindow"
import { Workout } from "types"

type Props = {
  workouts?: Array<Workout> | Workout | []
  onDelete?: any
}
export default function ScheduledWorkouts(props: Props) {
  let Component = <div />
  if (Array.isArray(props.workouts)) {
    const components = props.workouts.map((workout, idx) => {
      return (
        <RemovableWindow removeHandler={() => props.onDelete(workout.id)} key={idx}>
          <ViewableWorkout workout={workout} />
        </RemovableWindow>
      )
    })
    Component = <div className="flex flex-col gap-y-2">{components}</div>
  } else if (typeof props.workouts !== "undefined") {
    Component = <ViewableWorkout workout={props.workouts} />
  }
  return Component
}
